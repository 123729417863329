/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';
import { SRectangle } from 'app/App.styled';
import { ClockFullIcon } from 'assets/icons';
import {
  getClientRequestTime,
  getIsTimeSynced,
  getServerTime,
  getShowTimeIsNotSyncedPopup,
  setShowTimeIsNotSyncedPopupFalse,
} from 'domains/time/Time.store';
import { Flex, Icon, MarginBox, Modal, Text, YellowButton } from 'UI';
import { LOADING, textFormatter } from 'utils';
import { useView } from 'utils/hooks/useView';

export function TimeNotSyncedPopupLarge() {
  const { t } = useTranslation();
  const isTimeSyncedSearchData = useSelector(getIsTimeSynced);
  const clientTime = useSelector(getClientRequestTime);
  const serverTime = useSelector(getServerTime);
  const showPopup = useSelector(getShowTimeIsNotSyncedPopup);
  const dispatch = useDispatch();

  if (!clientTime || !serverTime) {
    return <></>;
  }

  return (
    <Modal
      width={990}
      footer={
        <MarginBox mt={20}>
          <Flex justify={'center'}>
            <YellowButton onClick={() => dispatch(setShowTimeIsNotSyncedPopupFalse())}>
              {t('common.action.confirm', 'Confirm')}
            </YellowButton>
          </Flex>
        </MarginBox>
      }
      open={showPopup && isTimeSyncedSearchData.searchStatus !== LOADING && isTimeSyncedSearchData.data === false}
    >
      <Flex direction={'row'} justify={'flex-start'}>
        <Icon IconComponent={ClockFullIcon} width={625} height={435} noPointer />
        <MarginBox ml={-190}>
          <SRectangle>
            <Text type={'h1_banner_light'}>{t('errors.time_not_synchronized.header', 'Time mismatch detected')}</Text>
            <Text type={'h6_black'}>
              {t(
                'errors.time_not_synchronized.description_top',
                'The time on your device is not synchronized with our server. This may cause errors or delays when using the application.',
              )}
            </Text>
            <Text type={'text'}>
              {t(
                'errors.time_not_synchronized.description_bottom',
                'Please check your device’s time settings and ensure it is accurate. You may need to enable automatic time synchronization.',
              )}
            </Text>
            <Text type={'text'}>
              {t('errors.time_not_synchronized.client_time', 'Your approximate time: {{clientTime}}', {
                clientTime: textFormatter.formatTime(new Date(clientTime)),
              })}
            </Text>
            <Text type={'text'}>
              {t('errors.time_not_synchronized.server_time', 'Server time: {{serverTime}}', {
                serverTime: textFormatter.formatTime(new Date(serverTime)),
              })}
            </Text>
          </SRectangle>
        </MarginBox>
      </Flex>
    </Modal>
  );
}

export function TimeNotSyncedPopupSmall() {
  const { t } = useTranslation();
  const isTimeSyncedSearchData = useSelector(getIsTimeSynced);
  const clientTime = useSelector(getClientRequestTime);
  const serverTime = useSelector(getServerTime);
  const showPopup = useSelector(getShowTimeIsNotSyncedPopup);
  const dispatch = useDispatch();

  if (!clientTime || !serverTime) {
    return <></>;
  }

  return (
    <Modal
      width={600}
      footer={
        <MarginBox mt={20}>
          <Flex justify={'center'}>
            <YellowButton size={'xlarge'} onClick={() => dispatch(setShowTimeIsNotSyncedPopupFalse())}>
              {t('common.action.confirm', 'Confirm')}
            </YellowButton>
          </Flex>
        </MarginBox>
      }
      open={showPopup && isTimeSyncedSearchData.searchStatus !== LOADING && isTimeSyncedSearchData.data === false}
    >
      <Flex direction={'row'} justify={'flex-start'}>
        <SRectangle>
          <Text type={'h2'}>{t('errors.time_not_synchronized.header', 'Time mismatch detected')}</Text>
          <Text type={'h6_black'}>
            {t(
              'errors.time_not_synchronized.description_top',
              'The time on your device is not synchronized with our server. This may cause errors or delays when using the application.',
            )}
          </Text>
          <Text type={'text'}>
            {t(
              'errors.time_not_synchronized.description_bottom',
              'Please check your device’s time settings and ensure it is accurate. You may need to enable automatic time synchronization.',
            )}
          </Text>
          <Text type={'text'}>
            {t('errors.time_not_synchronized.client_time', 'Your approximate time: {{clientTime}}', {
              clientTime: textFormatter.formatTime(new Date(clientTime)),
            })}
          </Text>
          <Text type={'text'}>
            {t('errors.time_not_synchronized.server_time', 'Server time: {{serverTime}}', {
              serverTime: textFormatter.formatTime(new Date(serverTime)),
            })}
          </Text>
        </SRectangle>
      </Flex>
    </Modal>
  );
}

export function TimeNotSyncedPopup() {
  return (
    <Layout>
      <Layout.Content>
        {useView([TimeNotSyncedPopupSmall, TimeNotSyncedPopupSmall, TimeNotSyncedPopupLarge], {})}
      </Layout.Content>
    </Layout>
  );
}
